const ASSUMED_BACKGROUND_COLOR = [255, 255, 255];
const DEFAULTBGCOLOR = "#ffffff";
const DEFAULTTEXTCOLOR = "hsl(0, 0%, 0%)";
const DEFAULTLINKCOLOR = "rgba(0, 0, 255, 1)";

export {
  ASSUMED_BACKGROUND_COLOR,
  DEFAULTBGCOLOR,
  DEFAULTTEXTCOLOR,
  DEFAULTLINKCOLOR,
};
